<template>
    <!--预览PDF-->
    <div class="page">
        <app-header :navIndex="4"></app-header>
        <section class="section-container">
            <div class="ql-container">
                <div class="ql-editor" v-html="details.details">
                </div>
            </div>
            <!--<div v-html="details.details"></div>-->
        </section>
        <!--<iframe id="iframe" frameborder="0"
                :src="link"></iframe>&lt;!&ndash;scrolling="no"&ndash;&gt;-->
    </div>
</template>
<script>
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {AppHeader},
        data(){
            return {
                id: '',
                link: '',
                details: {}
            }
        },
        created(){
            this.id = this.$route.query.id
//            this.link = this.$route.query.link
//            console.log(this.link)
            this.initData()
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "ASC精品酒业"
            document.getElementById("bodyId").appendChild(div);
//            var screenWidth = document.body.clientWidth
//            const iframe = document.getElementById('iframe');
//            var width = 1024
//            var height = iframe.contentWindow.document.documentElement.clientHeight
//            iframe.style.width = screenWidth + 'px'
//            iframe.style.height = 1000 + 'px'
        },
        methods: {
            initData(){
                this.$api.web.getCourseDetails({id: this.id}).then(res => {
                    this.details = res
                })
            },
        }
    }
</script>
<style>
    img{
        max-width: 100%;
    }
</style>
<style lang="scss" scoped>
    .page {
        width: 100%;
        height: auto;
        margin-top: 60px;
        @media (max-width: $screen-md) {
            margin-top: 0px;
        }
    }

    #iframe {
        margin-top: 60px;
    }

    .section-container {
    }
</style>
